<template>
  <main class="OccurrencesConfig">
    <TheSubHeader
      title="Configuração de ocorrências"
      page-title-tag="Occurrences Config"
    />
    <div class="OccurrencesConfig__main">
      <div class="OccurrencesConfig__content">
        <div class="OccurrencesConfig__flexWrapper">
          <h5>
            Lojistas no Fluxo de Ocorrência
          </h5>
          <label>Selecione as lojas que estarão no fluxo de ocorrência:</label>
          <!-- Integrated Stores -->
          <div class="OccurrencesConfig__storeSelect">
            <BaseTreeSelect
              ref="lojista"
              v-model="storesSelected"
              name="Lojista"
              label="Lojista"
              placeholder="Selecione"
              multiple
              :select-options="storesTreeSelect"
              :disabled="!storesTreeSelect.length"
              :clearable="false"
              :hide-select-all="true"
              @input="updateStores"
            />
          </div>
        </div>

        <div v-if="storesTags.length" class="OccurrencesConfig__flexWrapper OccurrencesConfig__tags">
          <label>Lojistas integrados:</label>
          <FiltersTag
            :tags="storesTags"
            :header="false"
            @removeTag="removeStore"
            @clear="clearAll"
          />
        </div>
        <div class="OccurrencesConfig__divider" />

        <div class="OccurrencesConfig__flexWrapper OccurrencesConfig__attendanceTime">
          <h5>
            SLA para atendimento
          </h5>
          <div class="OccurrencesConfig__attendanceDefault">
            <label>Tempo padrão para o atendimento de ocorrência:</label>
            <BaseDatePicker
              v-model="standardAttendanceTimeSelected"
              style="max-width: 94px;"
              :type="'time'"
              :format="'m:ss'"
              :time-picker-default="false"
              :formatter="defaultAttendanceTimeFormat"
              :show-hour="false"
              :range="false"
              :placeholder="''"
              :prepend-icon="false"
              :clearable="false"
              @input="updateStandardAttendanceTime"
            />
          </div>
          <template v-if="storesSelected && storesSelected.length">
            <label>Tempo específico por lojista:</label>
          </template>
          <!-- SLA config table -->
          <OccurrencesConfigTable
            ref="OccurrencesConfigTable"
            @hasChanged="hasChanged"
          />
        </div>
        <div class="OccurrencesConfig__ButtonsWrapper">
          <BaseButton
            class="CancelButton"
            label="Cancelar"
            filled
            color="white"
            text-color="black"
            @click="cancel"
          />

          <BaseButton
            label="Salvar"
            filled
            color="primary-base"
            text-color="white"
            :disabled="!hasChanges"
            @click="save"
          />
        </div>
      </div>
    </div>
    <Dialog
      v-if="showSuccessDialog"
      label-confirm="Ok"
      warning="Configurações do fluxo de ocorrência realizadas com sucesso"
      label-cancel=""
      @confirm="showSuccessDialog = false"
    />
  </main>
</template>

<script>
import { TheSubHeader } from '@/components/organisms'
import { BaseButton, BaseDatePicker, BaseTreeSelect } from '@/components/atoms'
import { Dialog, FiltersTag } from '@/components/molecules'
import OccurrencesConfigTable from './OccurrencesConfigTable.vue'
import { mapActions, mapState } from 'vuex'
import { controlTower } from '@/api'
import { defaultAttendanceTimeFormatter } from '../utils'

export default {
  name: 'OccurrencesConfig',
  components: {
    BaseButton,
    BaseDatePicker,
    BaseTreeSelect,
    Dialog,
    FiltersTag,
    TheSubHeader,
    OccurrencesConfigTable
  },
  data() {
    return {
      storesSelected: null,
      storesTreeSelect: [],
      standardAttendanceTimeSelected: null,
      defaultAttendanceTimeFormat: {
        stringify: defaultAttendanceTimeFormatter,
      },
      showSuccessDialog: false,
      hasChanges: false
    }
  },

  computed: {
    ...mapState({
      standardAttendanceTime: ({ controlTower }) => controlTower.occurrencesConfig.standardAttendanceTime,
      storesConfig: ({ controlTower }) => controlTower.occurrencesConfig.storesConfig,
      table: ({ controlTower }) => controlTower.occurrencesConfig.storesConfigTable,
    }),

    storesTags() {
      return this.storesSelected?.length ? [...this.storesSelected] : []
    }
  },

  mounted() {
    this.fetchConfig()
  },

  methods: {
    ...mapActions({
      fetchStores: 'stores/fetchStoresAsync',
      fetchStandardAttendanceTime: 'controlTower/fetchStandardAttendanceTime',
      setStandardAttendanceTime: 'controlTower/setStandardAttendanceTime',
      fetchOccurrencesConfig: 'controlTower/fetchOccurrencesConfig',
      removeOccurrencesConfigItem: 'controlTower/removeOccurrencesConfigItem',
      clearOccurrencesConfigContent: 'controlTower/clearOccurrencesConfigContent',
      setLoading: 'loading/setLoading',
    }),

    async fetchConfig(){
      this.setLoading(true)
      const standardAttendanceTimeValue = await this.fetchStandardAttendanceTime()
      this.standardAttendanceTimeSelected = new Date().setHours(0, 0, standardAttendanceTimeValue)
      this.storesTreeSelect = await this.fetchStores()
      this.fetchOccurrencesConfig()
        .then(() => {
          this.$refs.OccurrencesConfigTable.addContent([...this.storesConfig])
          this.storesSelected = [...this.storesConfig.map(store => [...store.stores]).flat()]
        })
        .finally(() => this.setLoading(false))
    },

    updateStandardAttendanceTime(timeValue) {
      this.setStandardAttendanceTime(timeValue)
      this.hasChanged()
    },

    removeStore(name, id){
      const newStoreSelected = this.storesSelected.filter(tag => tag.id !== id)
      const storeRemoved = this.storesSelected.find(store => store.id === id)
      this.storesSelected = [...newStoreSelected]

      if(storeRemoved.parent_id?.id){
        const storesWithSameParent = newStoreSelected.filter(({ id }) => id === storeRemoved.parent_id.id)

        if(!storesWithSameParent.length) {
          this.removeOccurrencesConfigItem(storeRemoved.parent_id.id)
          this.hasChanged()
        }
      }
    },

    clearAll(){
      this.storesSelected = null
      this.clearOccurrencesConfigContent()
      this.hasChanged()
    },

    updateStores() {
      if(!this.storesSelected?.length) return

      const parentStoresSelected = this.storesSelected
        .map(store => {
          const { parent_id } = store
          if(!parent_id?.id)
            return null

          return {
            id: parent_id.id,
            name: parent_id.name
          }
        }).filter(store => !!store)

      this.$refs.OccurrencesConfigTable.addContent([...parentStoresSelected])
    },

    cancel() {
      this.$router.push('/occurrence')
    },

    getSeconds(timestamp) {
      const date = new Date(timestamp)
      return (date.getMinutes() * 60) + date.getSeconds()
    },

    hasChanged(changes = true) {
      this.hasChanges = changes
    },

    async save() {
      const stores = this.table.content.map((store) => {
        const sla = this.getSeconds(store.slaInput[0].value)
        const storesChilds = this.storesSelected.map(({ id }) => id)

        return {
          id: store.id,
          sla,
          stores: [...storesChilds]
        }
      })

      const body = {
        'standard_time': this.getSeconds(this.standardAttendanceTimeSelected),
        stores
      }

      this.setLoading(true)
      controlTower.saveOccurrencesConfig(
        body,
        () => {
          this.fetchConfig()
          this.showSuccessDialog = true
          this.hasChanged(false)
        },
        (e) => (this.endpointErrors = e.validation),
        () => this.setLoading(false)
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.OccurrencesConfig {
    &__main {
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1180px) 1fr;
    grid-template-areas: ". main .";
    grid-column-gap: 1rem;
  }

  &__content {
    grid-area: main;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 35px;
  }

  &__flexWrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;

    h5 {
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    label {
      font-size: 0.85rem;
    }
  }

  &__storeSelect {
    width: 280px;
  }

  &__attendanceTime {
    width: 490px;
  }

  &__tags{
    width: 450px;

    /deep/ .FiltersTag__allTags {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 6px;

      .FiltersTag__tag {
        max-width: unset;
        border-radius: 2px;
        margin: 0;
      }

      .BaseTag__close {
        padding: 0 8px;
      }

      .BaseTag__close:before {
        content: " ";
        position: absolute;
        display: block;
        height: 18px;
        border-left: 1.5px solid #fff;
        left: 0;
        top: 3px;
      }

      .FiltersTag__toggle, .FiltersTag__clear {
        height: 26px;
        min-width: 85px;

        span {
          font-size: 12px;
        }
      }
    }
  }

  &__attendanceDefault {
    display: flex;
    align-items: flex-end;
    gap: 12px;
    margin: -20px 0 10px;
  }

  &__divider {
    width: 100%;
    border-bottom: 1px solid #E5E5E5;
  }

  &__ButtonsWrapper {
    display: flex;
    gap: 20px;
    justify-content: flex-end;

    button {
      width: 140px;
      height: 36px;
    }

    .CancelButton {
      border: 1.5px solid black;
    }
  }
}
</style>