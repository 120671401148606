<template>
  <main>
    <template v-if="table.content.length">
      <DataTable
        :header="table.headers"
        :table="table.content"
        @edit="editStoreContent"
        @checkConfirm="editStoreContent"
      />
    </template>
  </main>
</template>

<script>
import { DataTable } from '@/components/molecules'
import { mapActions, mapState } from 'vuex'
import { defaultAttendanceTimeFormatter } from '../utils'

export default {
  name: 'OccurrencesConfigTable',
  components: {
    DataTable,
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      table: ({ controlTower }) => controlTower.occurrencesConfig.storesConfigTable,
      standardAttendanceTime: ({ controlTower }) => controlTower.occurrencesConfig.standardAttendanceTime,
    }),
  },

  watch: { 
    standardAttendanceTime: function() {
      this.checkAttendanceTimeDefault()
    }
  },

  methods: {
    ...mapActions({
      setOccurrencesConfigContent: 'controlTower/setOccurrencesConfigContent',
    }),

    getSeconds(timestamp) {
      const date = new Date(timestamp)
      return (date.getMinutes() * 60) + date.getSeconds()
    },

    addContent(stores){
      if(!stores?.length) return

      const newContent = this.addStores(stores)

      if(this.table.content.length < newContent.length)
        this.$emit('hasChanged')

      this.setOccurrencesConfigContent(newContent)
    },

    addStores(stores) {
      const newContent = [...this.table.content]
      const storesIds = new Set()

      for (let index = 0; index < stores.length; index++) {
        const { id, name, sla } = stores[index]

        if(storesIds.has(id) ||
          newContent.some((store) => store.id === id)
        ) continue

        storesIds.add(id)

        const slaDate = sla ? new Date(this.standardAttendanceTime).setHours(0, 0, sla) : this.standardAttendanceTime
        const timelabel = !sla || this.getSeconds(slaDate) === this.getSeconds(this.standardAttendanceTime) ? 'Tempo Padrão' : defaultAttendanceTimeFormatter(slaDate)

        newContent.push({
          id,
          name,
          slaInput: [{
            id:`sla-${id}`,
            value: slaDate,
            timelabel: timelabel,
            type: 'time',
            format: 'm:ss',
            formatter: { stringify: defaultAttendanceTimeFormatter },
            showHour: false,
            range: false,
            placeholder: '',
            prependIcon: false,
            clearable: false,
            style: 'max-width: 74px; max-height: 32px; background: unset;',
            input: false,
            customOpen: true,
            open: true
          }],
          actions: [
            {action_id: 'edit', has_permission: true},
            {action_id: 'checkConfirm', has_permission: false},
          ]
        })
      }

      return newContent
    },

    hasMinimalTime(timestamp, minSeconds){
      const timeSeconds = this.getSeconds(timestamp)

      return timeSeconds >= minSeconds
    },

    editStoreContent(data) {
      const storeIndex = this.table.content.findIndex(store => store.id === data.id)
      const { id, name, sla, slaInput } = this.table.content[storeIndex]

      const newStoreContent = this.editStoreAttendanceTime({ 
        id, name, sla, slaInput: slaInput[0] 
      })

      const newContent = [...this.table.content]
      newContent.splice(storeIndex, 1, newStoreContent)
      this.setOccurrencesConfigContent(newContent)
    },

    editStoreAttendanceTime({ id, name, sla, slaInput }) {
      let newValue = sla || slaInput.value || this.standardAttendanceTime

      if(slaInput.input && slaInput.value) {
        if(this.hasMinimalTime(slaInput.value, 50)) {
          newValue = slaInput.value
          this.$emit('hasChanged')
        }
      }

      let newTimeLabel = defaultAttendanceTimeFormatter(newValue)

      if(this.getSeconds(newValue) === this.getSeconds(this.standardAttendanceTime)) {
        newTimeLabel = 'Tempo padrão'
      }

      return {
        id,
        name, 
        slaInput: [{ ...slaInput, value: newValue, timelabel: newTimeLabel, input: !slaInput.input }],
        actions: [
          {action_id: 'edit', has_permission: slaInput.input},
          {action_id: 'checkConfirm', has_permission: !slaInput.input},
        ]
      }
    },

    checkAttendanceTimeDefault() {
      const newContent = this.table.content.map(store => {
        const slaInput = store.slaInput[0]
        let newTimeLabel = slaInput.timelabel

        if(this.getSeconds(slaInput.value) === this.getSeconds(this.standardAttendanceTime)) {
          newTimeLabel = 'Tempo padrão'
        }

        return {
          id: store.id,
          name: store.name,
          slaInput: [{ ...slaInput, timelabel: newTimeLabel }],
          actions: [...store.actions],
        }
      })

      this.setOccurrencesConfigContent(newContent)
    },
  },
}
</script>